<script>
import {mapState} from "vuex";

export default {
  name: 'PriceDisplayCell',
  data: function () {
    return {
      price: false,
      priceAge: false,
      showArticleSearch: false,
      searchTerm: '',
      selectedArticle: false,
      showPopover: false,
      reassignmentMode: 'price',
      expiryDate: '',
      shouldHighlight: false,
      shouldHighlightSalePrice: false,
      shouldHighlightSaleQuantityPrice: false,
      className: ''
    };
  },
  beforeMount() {
    if (!this.params.value) {
      return;
    }

    const prices = this.params.value;

    if (prices[this.params.index]) {
      this.price = prices[this.params.index];
      this.priceAge = this.getReadableDifference(new Date(this.price.price_date).getTime());

      if (this.price.expires_at !== null) {
        this.expiryDate = this.price.expires_at;
      } else {
        const priceDate = new Date(this.price.price_date);
        this.expiryDate = (new Date(priceDate.setMonth(priceDate.getMonth() + 4))).toLocaleDateString();
      }
    }

    if (this.selectedSuppler) {
      if (this.price.supplier_id === this.selectedSuppler.id) {
        this.shouldHighlight = true;
      }
    }

    if (this.params.data && this.params.showSalePriceCandidate) {
      if (this.params.data.candidate_price_record_id !== null) {
        if (this.price.id === this.params.data.candidate_price_record_id) {
          this.shouldHighlightSalePrice = true
        }
      }
    }

    if (this.params.data && this.params.showSaleQuantityCandidate) {
      if (this.params.data.candidate_quantity_price_record_id !== null) {

        if (!this.price.id) {
          return;
        }

        const priceIds = this.params.data.candidate_quantity_price_record_id.split(',');
        if (priceIds.includes(this.price.id.toString())) {
          this.shouldHighlightSaleQuantityPrice = true
        }
      }
    }

    this.className = (this.shouldHighlight || this.shouldHighlightSalePrice) ? 'highlightPrice ' : '';

    if (!(this.shouldHighlight || this.shouldHighlightSalePrice) && this.shouldHighlightSaleQuantityPrice) {
      this.className += ' highlightQty';
    }
  },
  methods: {
    toggleArticleSearch(mode = 'price') {
      this.reassignmentMode = mode;
      this.showArticleSearch = !this.showArticleSearch;
      this.selectedArticle = false;
      this.$store.dispatch('newPriceList/clearArticleSearch');
      this.$store.dispatch('prices/clearCompletedStatus');
    },
    performSearch() {
      this.$store.dispatch('newPriceList/searchArticles', this.searchTerm)
    },
    selectArticle(article) {
      this.selectedArticle = article;
    },
    dismissPopover(price) {
      this.$refs['pricePopover' + price.id].$emit('close');
    },
    saveReassignment(article, price) {
      switch (this.reassignmentMode) {
        case 'price':
          this.$store.dispatch('prices/reassignPrice', {
            article: article,
            price: price
          });
          break;

        case 'article':
          this.$store.dispatch('prices/reassignArticle', {
            article: article,
            price: price
          });
          break;
      }
    },
    invalidatePrice(price) {
      this.$store.dispatch('prices/invalidatePrice', {
        price: price
      }).then((() => {
        this.$store.dispatch('articles/triggerRefresh')
      }));

    }
  },
  computed: mapState({
    searchResults: (state) => state.newPriceList.articleResults,
    isFetching: (state) => state.newPriceList.isFetching,
    isUpdatingPrice: (state) => state.prices.isFetching,
    isDoneUpdatingPrice: (state) => state.prices.isComplete,
    selectedSuppler: (state) => state.articles.filterSupplier
  })
};
</script>

<template>
  <div :class="className">
    <div class="priceDisplay" :id="`popover-target-` + price.id " v-if="price">
      <div class="left">
        <div class="priceDisplay__price">
          <strong>
            <money-format
                :currency-code="price.currency.code"
                :value="parseFloat(price.unit_price)"
            ></money-format>
          </strong>
          <span class="legend statistic" v-if="price.is_purchase_statistic"></span>
          <span class="legend promotion" v-if="price.is_promotional_price"></span>
          <span class="legend notLatest" v-if="(! price.is_from_latest_price_list && ! price.is_purchase_statistic)"></span>
        </div>
        <div class="priceDisplay__age"><span
            class="priceDisplay__age_Supplier">{{ price.supplier.name.length > 15 ? (price.supplier.name.substr(0, 15)) : (price.supplier.name) }}</span>
          - <span class="priceDisplay__age_Age">{{ priceAge }}</span></div>
      </div>
      <b-popover :target="`popover-target-` + price.id" triggers="hover" placement="right"
                 :ref="`pricePopover` + price.id">
        <template #title>
          <div class="left"><span>Price Details</span></div>
          <div class="right">
            <b-button variant="light" size="sm" @click="dismissPopover(price)">
              <b-icon-x></b-icon-x>
            </b-button>
          </div>
        </template>
        <b-overlay :show="isUpdatingPrice">
          <div class="priceDetails">
            <div class="priceDetails__item">
              <div class="priceDetails__label">Supplier Ref.</div>
              <div class="priceDetails__value">
                {{ price.supplier_article ? price.supplier_article.supplier_identifier : "None" }}
              </div>
            </div>
            <div class="priceDetails__item">
              <div class="priceDetails__label">Supplier Name</div>
              <div class="priceDetails__value">{{ price.supplier.name }}</div>
            </div>
            <div class="priceDetails__item">
              <div class="priceDetails__label">Supplier Article</div>
              <div class="priceDetails__value">{{
                  price.supplier_article ? price.supplier_article.name : price.title
                }}
              </div>
            </div>
            <div class="priceDetails__item">
              <div class="priceDetails__label">Supplier EANs</div>
              <div class="priceDetails__value">Article:
                {{ price.supplier_article ? price.supplier_article.ean_article : " " }} | Case:
                {{ price.supplier_article ? price.supplier_article.ean_case : " " }}
              </div>
            </div>
            <div class="priceDetails__item">
              <div class="priceDetails__label">Price / {{ price.unit_type }}</div>
              <div class="priceDetails__value">
                <money-format
                    :currency-code="price.currency.code"
                    :value="parseFloat(price.unit_price)"
                ></money-format>
                <span v-if="price.is_purchase_statistic">(Purchase Stat.)</span>
              </div>
            </div>
            <div class="priceDetails__item">
              <div class="priceDetails__label">{{ price.unit_type }} / {{ price.package_type }}</div>
              <div class="priceDetails__value">{{ price.bottles_per_case }}</div>
            </div>
            <div class="priceDetails__item">
              <div class="priceDetails__label">Comment</div>
              <div class="priceDetails__value">{{ price.comment ? price.comment : '-' }}</div>
            </div>
            <div class="priceDetails__item">
              <div class="priceDetails__label">Price / {{ price.package_type }}</div>
              <div class="priceDetails__value">
                <money-format
                    :currency-code="price.currency.code"
                    :value="parseFloat(price.package_price)"
                ></money-format>
              </div>
            </div>
            <div class="priceDetails__item">
              <div class="priceDetails__label">Stock</div>
              <div class="priceDetails__value">{{
                  price.stock_amount === null ? 'No info' : parseInt(price.stock_amount)
                }}
              </div>
            </div>
            <div class="priceDetails__item">
              <div class="priceDetails__label">Expiry</div>
              <div class="priceDetails__value">
                {{ this.expiryDate }}
              </div>
            </div>
          </div>
          <div class="actions">
            <div v-if="showArticleSearch">

              <div v-if="selectedArticle">
                <div v-if="! isDoneUpdatingPrice">
                  <span>{{ selectedArticle.latina_article_id }} - {{ selectedArticle.title }}</span>
                  <b-button variant="warning" @click="toggleArticleSearch" size="sm">Back</b-button>
                  <b-button variant="primary" @click="saveReassignment(selectedArticle, price)" size="sm">Save
                  </b-button>
                </div>
                <div v-else>
                  <span>Assignment updated! Please refresh</span>
                </div>
              </div>
              <div v-else>
                <div class="articleSearch">
                  <b-form-input id="articleSearchSearch" ref="searchInput" size="sm" v-on:keyup.enter="performSearch"
                                v-model="searchTerm"></b-form-input>
                  <b-button variant="primary" @click="performSearch" size="sm">Search</b-button>
                  <b-button variant="warning" @click="toggleArticleSearch" size="sm">Back</b-button>
                </div>
                <b-overlay :show="isFetching" class="articleResults__container">
                  <div class="articleResults__results">
                    <div class="articleResults__result" v-for="result in searchResults" :key="result.id"
                         v-on:click="selectArticle(result)">
                      {{ result.latina_article_id }} - {{ result.title }}
                    </div>
                  </div>
                </b-overlay>
              </div>
            </div>
            <div v-else>
              <b-button variant="primary" size="sm" @click="toggleArticleSearch('price')">Reassign Price</b-button>
              <b-button variant="info" size="sm" @click="toggleArticleSearch('article')">Reassign Supplier Article
              </b-button>
              <b-button variant="danger" size="sm" @click="invalidatePrice(price)">Invalidate</b-button>
            </div>
          </div>
        </b-overlay>
      </b-popover>
    </div>
    <div class="priceDisplay" v-else>
      -
    </div>
  </div>
</template>


<style lang="scss">

.highlightPrice {
  background: #fffad9;
}


.highlightQty {
  background: #ffd9f1;
}

.priceDisplay {
  display: flex;
  height: 100%;

  .left {
    display: flex;
    flex-flow: column;
    justify-content: center;
    font-size: 12px;
    line-height: 1.3;
    align-items: flex-start;
    text-align: left;
    width: 100%;
  }

  .right {

  }

  &__price {
    display: flex;
    align-items: center;
  }

  &__age {

    &_Supplier, &_Age {
      font-size: 10px;
    }


  }
}

.popover, .priceDetails__item {
  min-width: 450px;
  width: 450px;
}

.priceDetails {

  &__item {
    display: flex;
    padding: 3px 0;
    font-size: 12px;
  }

  &__label {
    width: 120px;
    text-align: right;
    padding-right: 10px;
  }

  &__value {
    text-align: left;
    font-weight: bold;
    width: 340px;
    display: flex !important;

    > span {
      margin-left: 10px;
      font-weight: normal;
    }
  }
}

.actions {
  padding: 10px;
  background: #d6f0ff;
  display: flex;
  justify-content: center;
  border-radius: 5px;

  button {
    margin: 0 3px;
    font-size: 12px;
  }

}

.articleSearch {
  display: flex;
  align-items: center;

  input {
    flex-grow: 1;
  }
}

.actions {
  .articleResults {

    &__results {
      height: 140px;
      position: fixed;
      background: #fff;
      padding: 5px 0;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      overflow-y: auto;
    }

    &__result {
      padding: 2px 10px;
      width: 100%;
      text-align: left;
      cursor: pointer;
      display: block;
      border-bottom: solid 1px #eee;
      transition: all 0.3s;

      &:hover {
        color: #1e90c3;
      }
    }
  }
}

</style>